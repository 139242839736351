export default [
    {
        num: 1,
        label: {
            en: 'New',
            et: 'Uus',
            ru: 'Новый'
        },
        color: 'green'
    },
    {
        num: 2,
        label: {
            en: 'Draft',
            et: 'Mustand',
            ru: 'Черновик'
        },
        color: 'yellow'
    },
    {
        num: 3,
        label: {
            en: 'Paid part.',
            et: 'Os. tasutud',
            ru: 'Опл. част.',
        },
        color: 'blue'
    },
    {
        num: 5,
        label: {
            en: 'Paid',
            et: 'Tasutud',
            ru: 'Оплачен',
        },
        color: 'dark-blue'
    },
]

// export default {
//     en: [
//         {num: 1, label: 'New', color: 'green'},
//         // {num: 3, label: 'Paid partially', color: 'blue'},
//         {num: 5, label: 'Paid', color: 'dark-blue'},
//     ],
//     et: [
//         {num: 1, label: 'Uus', color: 'green'},
//         // {num: 3, label: 'Makstud partially', color: 'blue'},
//         {num: 5, label: 'Makstud', color: 'dark-blue'},
//     ],
//     ru: [
//         {num: 1, label: 'Новый', color: 'green'},
//         // {num: 3, label: 'Оплачен partially', color: 'blue'},
//         {num: 5, label: 'Оплачен', color: 'dark-blue'},
//     ]
// }
