export default [
    {
        id: 1,
        label: {
            en: 'Didn\'t fit',
            et: 'Ei sobinud',
            ru: 'Не подошло'
        },
    },
    {
        id: 2,
        label: {
            en: 'Mistake',
            et: 'Viga',
            ru: 'Ошибка'
        },
    },
    {
        id: 3,
        label: {
            en: 'Customer refused',
            et: 'Klient keeldus',
            ru: 'Отказ клиента'
        },
    },
    {
        id: 5,
        label: {
            en: 'Warranty',
            et: 'Garantii',
            ru: 'Гарантия'
        },
    },
    {
        id: 6,
        label: {
            en: 'Defective product',
            et: 'Defektne toode',
            ru: 'Бракованный товар'
        },
    },
    {
        id: 7,
        label: {
            en: 'Body',
            et: 'Korpus',
            ru: 'Корпус'
        },
    },
    {
        id: 9,
        label: {
            en: 'Another reason',
            et: 'Muu põhjus',
            ru: 'Другая причина'
        },
    },
]