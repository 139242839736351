export default [
    {
        id: 1,
        label: {
            en: 'Return',
            et: 'Tagastus',
            ru: 'Возврат'
        },
    },
    {
        id: 2,
        label: {
            en: 'Warehouse',
            et: 'Ladu',
            ru: 'На склад'
        },
    },
]