<template>
  <Button :label="label" icon="ti-plus" class="p-button-success p-button-raised action-button"/>
</template>

<script>
export default {
  name: 'AddButton',
  props: {
    label: String
  }
}
</script>

<style scoped>
.action-button {
  margin: 0 3px;
}
</style>