<template>
  <div v-if="!position.isNew">
    <a @click.prevent="togglePositionHistoryOverlay($event, position.id)" href="#" class="p-link table-link-icon">
      <i class="ti-time"></i>
      <span style="font-size: 0.9em; position: relative; top: -1px;" class="p-ml-1" v-if="computedPositionHistoryLength">{{ computedPositionHistoryLength }}</span>
    </a>
<!--    <Button @click="togglePositionHistoryOverlay($event, position.id)" icon="ti-time" :label="position.history?.length" class="p-button-text">-->
<!--      <span style="font-size: 0.9em; position: relative; top: -1px;" v-if="position.history && position.history.length" class="p-ml-1">{{ position.history.length }}</span>-->
<!--    </Button>-->
    <OverlayPanel :ref="'ph-' + position.id">
      <div v-if="position.deleted_at">
        <span><b>{{ $t('Deleted') }}:</b> {{ formatDate(position.deleted_at) }},</span> <span v-if="position.deletedBy?.first_name">{{ position.deletedBy.first_name }}</span> <span v-if="position.deletedBy?.last_name">{{ position.deletedBy.last_name }}</span>
        <hr>
      </div>
<!--      <div v-if="position.history && position.history.length">-->
      <div v-if="computedPositionHistoryArray">
        <div v-for="(history, index) of computedPositionHistoryArray" :key="index" class="p-mr-3">
          <div class="p-mb-2">
            <span v-if="history.created_at" >{{ formatDate(history.created_at) }},</span> <span v-if="history.creator?.first_name">{{ history.creator.first_name }}</span> <span v-if="history.creator?.last_name">{{ history.creator.last_name }}</span>
          </div>
          <div v-if="history.supplier" class="p-mb-2"><b>{{ $t('Supplier') }}:</b> {{ history.supplier[0] }} <i v-if="history.supplier[0]" style="font-size: 0.9em" class="ti-arrow-right"></i> {{ history.supplier[1] || '-' }}</div>
          <div v-if="history.employee" class="p-mb-2"><b>{{ $t('Employee') }}:</b> {{ history.employee[0] }} <i v-if="history.employee[0]" style="font-size: 0.9em" class="ti-arrow-right"></i> {{ history.employee[1] || '-' }}</div>
          <div v-if="history.code" class="p-mb-2"><b>{{ $t('Code') }}:</b> {{ history.code[0] }} <i v-if="history.code[0]" style="font-size: 0.9em" class="ti-arrow-right"></i> {{ history.code[1] || '-' }}</div>
          <div v-if="history.name" class="p-mb-2"><b>{{ $t('Name') }}:</b> {{ history.name[0] }} <i v-if="history.name[0]" style="font-size: 0.9em" class="ti-arrow-right"></i> {{ history.name[1] || '-' }}</div>
          <div v-if="history.qty" class="p-mb-2"><b>{{ $t('Qty') }}:</b> {{ formatDecimal(history.qty[0]) }} <i v-if="history.qty[0]" style="font-size: 0.9em" class="ti-arrow-right"></i> {{ formatDecimal(history.qty[1]) }}</div>
          <div v-if="history.purchase_price" class="p-mb-2"><b>{{ $t('Purchase price') }}:</b> {{ formatCurrency(history.purchase_price[0], 2, 4) }} <i style="font-size: 0.9em" class="ti-arrow-right"></i> {{ formatCurrency(history.purchase_price[1], 2, 4) }}</div>
          <div v-if="history.sell_price" class="p-mb-2"><b>{{ $t('Selling price') }}:</b> {{ formatCurrency(history.sell_price[0], 2, 4) }} <i v-if="history.sell_price[1]" style="font-size: 0.9em" class="ti-arrow-right"></i> {{ formatCurrency(history.sell_price[1], 2, 4) }}</div>
          <div v-if="history.discount" class="p-mb-2"><b>{{ $t('Discount') }}:</b> {{ formatDecimal(history.discount[0]) }}% <i style="font-size: 0.9em" class="ti-arrow-right"></i> {{ formatDecimal(history.discount[1]) }}%</div>
          <div v-if="history.tax" class="p-mb-2"><b>{{ $t('Tax') }}:</b> {{ formatDecimal(history.tax[0]) }}% <i style="font-size: 0.9em" class="ti-arrow-right"></i> {{ formatDecimal(history.tax[1]) }}%</div>
          <div v-if="history.returned" class="p-mb-2"><b>{{ $t('Returned') }}:</b> {{ formatDecimal(history.returned) }}</div>
          <div v-if="history.type" class="p-mb-2"><b>{{ $t('Type') }}:</b> {{ ReducedPositionsTypes.find(reason => reason.id === history.type)?.label[$i18n.locale] }}</div>
          <div v-if="history.reason" class="p-mb-2"><b>{{ $t('Reason') }}:</b> {{ ReducedPositionReasons.find(reason => reason.id === history.reason)?.label[$i18n.locale] }}</div>
          <div v-if="history.accounting_code" class="p-mb-2"><b>Accounting code:</b> {{ history.accounting_code[0] }} <i v-if="history.accounting_code[0]" style="font-size: 0.9em" class="ti-arrow-right"></i> {{ history.accounting_code[1] }}</div>
          <hr>
        </div>
      </div>
      <div v-if="position.creator">
        <span v-if="position.created_at"><b>{{ $t('Created') }}:</b> {{ formatDate(position.created_at) }},</span> <span>{{ position.creator.first_name }} {{ position.creator.last_name }}</span>
      </div>
    </OverlayPanel>
  </div>
</template>

<script>
// import OverflowOverlayPanel from '@/components/OverflowOverlayPanel'
import ReducedPositionReasons from '@/translations/ReducedPositionReasons'
import formatMixins from "@/mixins/formatMixins";
import ReducedPositionsTypes from "@/translations/ReducedPositionTypes";

export default {
  mixins: [ formatMixins ],
  // components: { OverflowOverlayPanel },
  name: 'PositionHistoryButton',
  props: {
    position: Object
  },
  data() {
    return {
      ReducedPositionReasons,
      ReducedPositionsTypes
    }
  },
  methods: {
    togglePositionHistoryOverlay(event, id) {
      if (!event || !id) {
        return false
      }

      this.$refs['ph-' + id.toString()].toggle(event);
    }
  },
  computed: {
    computedPositionHistoryLength() {
      return this.position?.history?.length ?? null
    },
    sortedPositionHistory() {
      if (this.computedPositionHistoryLength) {
        return {...this.position}.history.sort((a, b) => b.created_at - a.created_at)
      } else {
        return null
      }
    },
    computedPositionHistoryArray() {
      if (this.sortedPositionHistory?.length) {
        const data = this.sortedPositionHistory.map(h => {
          return {
            ...JSON.parse(h.data),
            creator: h.creator,
            created_at: h.created_at
          }
        })
        return data
      } else {
        return null
      }
    }
  }
}
</script>
